.mySwiper img {
  width: 100%;
  height: auto;
  object-fit: cover;
}


.card-container {
  max-width: 100%; /* يمكنك تعديل الحجم كما تريد */
  overflow: hidden;
}

.mySwiper {
  height: 200px; /* يمكنك تعديل الحجم كما تريد */
}

.black-foto {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}